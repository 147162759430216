<template>
  <div>
    <h4>Page not Found</h4>
    <router-link to="/app">Go back home</router-link>
  </div>
</template>

<script>
  export default {
    name: "Login",
    props: {},
    components: {},

    data() {
      return {};
    },
    computed: {},
    methods: {},
    mounted() {
      this.setBreadcrumbs([]);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
  }
</style>
